$galago-color: #016e7f;
$primary: #016e7f;
$error: #A71E17;
$secondary: #adadad;
$success: #2eae4e; 

//* MEDIA QUERIES
$small: 475px;
$medium: 786px;
$large: 1080px;

//* PRIMARY COLORS TEAL

$primary-teal-10: #E6F1F2;
$primary-teal-20 : #D9E9EC;
$primary-teal-30 : #B0D2D7;
$primary-teal-40 : #9EBDC2;
$primary-teal-50 : #016e7f;
$primary-teal-60 : #015866;
$primary-teal-70 : #01535F;
$primary-teal-80 : #01424C;
$primary-teal-90 : #003139;
$primary-teal-100 : #00272C;
 

//* SECONDARY COLOR ORANGE

$secondary-orange-10: #FFF4EB;
$secondary-orange-20: #FFEEE1;
$secondary-orange-30: #FEDCC2;
$secondary-orange-40: #FDB175;
$secondary-orange-50: #FC8F3A;
$secondary-orange-60: #E38134;
$secondary-orange-70: #CA722E ;
$secondary-orange-80: #BD6B2C;
$secondary-orange-90: #653917;
$secondary-orange-100: #321D0C;

//* SECONDARY COLOR LIGHT GREEN

$secondary-green-10: #F7FAEB;
$secondary-green-20: #E7F1C3;
$secondary-green-30: #D7E89B;
$secondary-green-40: #C6DF72;
$secondary-green-50: #B6D64A;
$secondary-green-60: #AED136;
$secondary-green-70: #4E5E18;
$secondary-green-80: #465416;
$secondary-green-90: #3D4913;
$secondary-green-100: #232A0B;


//* SECONDARY COLOR lIGHT BLUE

$secondary-blue-10: #F9FFFF;
$secondary-blue-20: #ECFEFF;
$secondary-blue-30: #DFFDFF;
$secondary-blue-40: #D2FCFF;
$secondary-blue-50: #C5FBFF;
$secondary-blue-60: #BFFAFF;
$secondary-blue-70: #ACE1E6;
$secondary-blue-80: #3E5153;
$secondary-blue-90: #394B4C;
$secondary-blue-100: #1F2929;


//* SECONDARY COLOR CYAN BLUE

$secondary-cyan-10: #EDF6F8;
$secondary-cyan-20: #CAE3E9;
$secondary-cyan-30: #A7D1DA;
$secondary-cyan-40: #84BECB;
$secondary-cyan-50: #4FA2B4;
$secondary-cyan-60: #4791A1;
$secondary-cyan-70: #3F8290;
$secondary-cyan-80: #3B7A87;
$secondary-cyan-90: #204148;
$secondary-cyan-100: #102024;



//* NEUTRAL COLORS CHARCOAL

$charcoal-10: #EBEBEB;
$charcoal-20: #E0E0E0;
$charcoal-30: #CECECE;
$charcoal-40: #C2C2C2;
$charcoal-50: #474747;
$charcoal-60: #333333;
$charcoal-70: #292929;
$charcoal-80: #1F1F1F;
$charcoal-90: #141414;
$charcoal-100: #0A0A0A;

//* NEUTRAL COLORS WHITE-SMOKE

$whitesmoke-10: #FEFEFE;
$whitesmoke-20: #FCFCFC;
$whitesmoke-30: #FAFAFA;
$whitesmoke-40: #F8F8F8;
$whitesmoke-50: #F6F6F6;
$whitesmoke-60: #F5F5F5; 
$whitesmoke-70: #E5E5E5;
$whitesmoke-80: #DDDDDD;
$whitesmoke-90: #C4C4C4;
$whitesmoke-100: #575757;

$font-family-satoshi: 'Satoshi', sans-serif;
$font-family-general-sans: 'General Sans', sans-serif;
$font-family-syne: 'Syne', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;


$font-size-10: 10px;
$font-size-13: 13px;
$font-size-12: 12px;
$font-size-14: 14px; 
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px; 
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-27: 27px;
$font-size-28: 28px;
$font-size-32: 32px; 
$font-size-34: 34px; 

