@import './variables.scss';

#hero-section {
  display: grid;
  place-items: center;

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
    opacity: 0.8;
  }
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: $whitesmoke-80;
    background: linear-gradient(338deg, rgba(211,211,211,1) 10%, rgba(1,110,127,0.16130959219625352) 30%, rgba(1,110,127,0.6038866230085784) 59%, rgba(128,128,128,0.5422619731486344) 87%, rgba(1,110,127,0.6010855025603992) 99%);
    opacity: 80%;
  }

  .get-ready-to-go {
    position: absolute;
    top: 7.5em;
    width: 100%;
    padding: 0.2rem 5rem;
    color: $whitesmoke-60;
    display: flex;
    gap: 2rem;
    flex-direction: column;

    .search-form-container {
      max-width: 1440px;
      width: 100%;
      align-self: center;
    }
    #search-form-main {
      align-items: center;
      
      background: $whitesmoke-10;
      background: linear-gradient(338deg, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.31817233729429273) 48%, rgba(211, 211, 211, 1) 100%, rgba(211, 211, 211, 1) 100%);
      color: black;
      border-radius: 8px;
      font-weight: $font-weight-medium;
      a{
        text-decoration: none;
      }
      .nav-tabs {
        border: none;
      }

      .nav-link {
        border: none;
      }
      a {
        text-decoration: none;
        
        &:hover {
          color: $whitesmoke-10 !important;
          
        }
      }
    }

    .tab-content {
      background-color: $whitesmoke-10;
      border-radius: 0 0px 8px 8px;
    }
  }

  .search-form-container {
    padding: 24px;
  }
}

.form-label {
  color: #333;
  font-family: 'Satoshi', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

#flights-switch {
  align-items: flex-start;
  border-radius: 15px;
  border: 1px solid $primary-teal-50;

  > a {
    border: unset;
  }

  > a:hover {
    background-color: $primary-teal-50;
    color: $whitesmoke-10;
  }

  > a:first-child {
    border-right: unset;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  > a:not(:first-child):not(:last-child) {
    border-right: unset;
    border-left: unset;
    border-radius: 12px;
  }

  > a:last-child {
    border-left: unset;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .form-label {
    color: $charcoal-60;
    font-family: 'General-Sans', sans-serif;
    font-size: $font-size-13;
    font-weight: $font-weight-medium;
  }
}

.search-flight-button {
  svg {
    display: -webkit-inline-box;
  }

  border: none;
  box-shadow: 1.95px 1.95px 2.6px 0px #00000026;
}


.search-flight-button:disabled{
  background-color: #adadad;
  opacity: 1;
}

.search-hotel-button {
  border: none;
  box-shadow: 1.95px 1.95px 2.6px 0px #00000026;
}

#passenger-details-holder {
  z-index: 1;
  padding: 16px;
  gap: 6px;
  border-radius: 8px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  // width: 278px !important;

  .passenger-count-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;

    span:first-child {
      color: $charcoal-60;
      font-size: 16px;
      font-weight: $font-weight-medium;
    }
    span:last-child {
      color: #333;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
    }
  }

  .passenger-count-btn {
    border: 1px solid transparent;
    background: $whitesmoke-20;
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  // details for guest and rooms for hotels
  .hotel-guest-room-info {
    margin-top: 16px;
    display: flex;
    padding: 14px 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: $secondary-cyan-10;

    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: var(--text-colors-text-primary, $charcoal-60);
      font-size: 12px;
      font-style: normal;
      line-height: normal;

      > a {
        cursor: pointer;
        color: $primary-teal-50;
        font-size: $font-size-12;
        font-style: normal;
        text-decoration-line: underline;
      }
    }
  }
}

.switch-arrow-svg {
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  // gap: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: $primary-teal-50;
  transition: 0.5s all ease-in-out;
}

.switch-arrow-svg:hover {
  transform: rotate(180deg);
}

.button-disabled {
  background-color: $charcoal-40 !important;
  opacity: 1 !important;
  // border-color: #fff !important;
}

#filters-holder {
  border: 2px solid $secondary-cyan-50;
  z-index: 1;
  border-radius: 8px;
  background: var(--color-shades-white-800, #fff);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: #adadad transparent;
  padding-top: 15rem;

  .filters-opt {
    width: 100%;
    padding: 12px;
    cursor: pointer;
  }

  .filters-opt:hover {
    background-color: $secondary-cyan-10;
  }

  .filters-opt:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .filters-opt:nth-child(8) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

}

#cabin-details-holder {
  // border: 2px solid $secondary-cyan-50;
  z-index: 1;
  border-radius: 8px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  width: 100%;

  .cabin-opt {
    width: 100%;
    padding: 12px;
    cursor: pointer;
  }

  .cabin-opt:hover {
    background-color: $secondary-cyan-50;
  }

  .cabin-opt:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .cabin-opt:nth-child(4) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
