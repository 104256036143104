@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}
/* body::-webkit-scrollbar {
  width: 15px;
} */

*,
::before,
::after {
  border-width: 0;
}

input:focus + label,
input:not(:placeholder-shown) + label {
  transform: translateY(-16px);
}

/* Custom scrollbar styling */
/* For webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #016e7f;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: orange;
}

::-webkit-scrollbar-track {
  background-color: #F9F9F9;
}
/* For Firefox */
/* scrollbar-width: thin;
scrollbar-color: #d9d9d9 #fff; */
