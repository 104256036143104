.article-hero-section {
  background-image: url("https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images/bohol-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: '#fff';
  gap: 6px;
  position: relative;

  .head-text {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    z-index: 1;
  }
  .overlay {
    background-color: #016e7f;
    position: absolute;
    opacity: 50%;
    height: 50vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .breadNav {
    display: flex;
    position: absolute;
    bottom: 10px;
    justify-content: flex-start;
    align-items: center;
  }
}

// Main Styles

// Author and Socials

.author_social {
  margin-top: 100px;
}
