.async-flight-locations {
  >div[aria-label='menu-options']{
    padding: unset;
    > a{
      padding-left: unset;
      padding-right: unset;
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid #ddd;

      > span{
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    >a:last-child{
      border-bottom: unset;
    }
  }

  ::placeholder{
    color: #A8A8A8;
    font-weight: 400;
  }



}

#async-flying-from, #async-flying-to{
  >a:hover{
    background-color: #E6F1F3;
    
    div{
      color: #016E7F;
    }
  
    div > svg{ 
      path{
        fill: #016E7F;
      }
    }
  }

  >a:focus, >a:active, >a:visited{
    background-color: #E6F1F3;
  }
}

#async-hotel{
  >a:hover{
    background-color: #E6F1F3;
    
    div{
      color: #016E7F;
    }
  
    div > svg{ 
      path{
        stroke: #016E7F;
      }
    }

   span {
      color: #016E7F;
    }
  }

  >a:focus, >a:active, >a:visited{
    background-color: #E6F1F3;
  }

}