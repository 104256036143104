$nav-tabs-border-color: #d9d9d9;

#search-tabs {
  
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  li {
    border-radius: 6px 6px 0 0;
    

  }
}

.nav-pills {
  display: flex;
  gap: 42px;
  
  > li.nav-item {
    > a.nav-link {
      color: #333;
      padding: 0px;
      > img {
        border-radius: 50px;
        width: 33px;
        height: 33px;
        margin-right: 10px;
      }

      span#user-name {
        padding-left: 10px;
        border-left: 1px solid #333;
      }
    }
  }
}
