.galago-card{
  border: 1px solid #E3E6E8;
  padding: 0;
  font-family: 'Satoshi', sans-serif;
  border-radius: 10px;

  .galago-card-title{
    border-bottom: 1px solid #E3E6E8;
    padding: .5em 1em;
    font-weight: 600;
    font-size: 20px;
  }

  .galago-card-body{
    padding: .5em 1em;
    // font-family: 'Satoshi', sans-serif;
  }
}

// flight history page
#flight-history-page{

  #flight-title{
    padding: 0;
    margin-top: 2em;
    display: flex;
    align-items: center;
    gap: 1em;

    > span{
      background-color: #B3FAFF;
      padding: 5px 10px 6px 10px;
      border-radius: 10px;
    }
  }

  #date-information{
    margin-bottom: 3em;
    padding: 1em 0 2em 0;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    align-items: center;

    > span{
      border-right: 1px solid #F0F0F0;
      padding: 0 2em;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    > span:first-child{
      padding-left: 0;
    }

    > span:last-child{
      border-right: none;
      padding-right: 0;
    }
  }

  #itinerary{

    .galago-card-body{
      font-weight: 500;
      > div {
        // aircraft details and baggage information section
        > div:nth-child(1){
          padding: 1em;
          display: flex;
          justify-content: space-between;
          gap: 1em;

          // airline logo and airline name
          > div{
            display: flex;
            align-items: center;
            gap: 5px;
          }

          // airline logo and name
          > div:first-child{
            .departure-return{
              background: #C6EDEB;
              padding: 10px 8px;
              border-radius: 15px;
              color: #1F5B6B;
              font-weight: 600;
              width: 110px;
              text-align: center;
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .airline-logo-and-name{
              background: #E9F4F6;
              border: 1px solid #CCF0EE;
              color: #1F5B6B;
              padding: 5px 8px;
              border-radius: 15px;
              display: flex;
              align-items: center;

              > img{
                border-radius: 100%;
                margin-right: 5px;
              }
            }
          }
        }
        // flight duration details
        > div:nth-child(2){
          padding: 1em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1em;

        > div{
          display: flex;
          flex-direction: column;
          gap: 1em;

          > div{
            display: flex;
            gap: 1em;
            align-items: center;

            > span{
              font-weight: 600;
            }
          }
        }
      }
      }
    }
  }
}

.middle-arrow{
  position: relative;
  background: #016E7F;
  height: 2px;
  width: 100%;

  ::before,
  ::after{
    position: absolute;
    content: '';
    background: #016E7F;
    height: 2px;
    width: 10px;
  }

  ::before{
    top: 4px;
    right: -2px;
    // rotate 160 degrees
    transform: rotate(-135deg);
  }

  ::after{
    top: 10px;
    right: -2px;
    // rotate 160 degrees
    transform: rotate(135deg);
  }

}

.passenger-accordion{
  padding: 0;
  font-weight: 500;

  li{
    background: #F7F9FA;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;

    > div.accordion-header{
      background: #fff;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      cursor: pointer;
      
      > div:first-child{
        display: flex;
        align-items: center;
      }

      > div:last-child{
        cursor: pointer;
      }
    }

    > div.accordion-body{
      display: flex;
      justify-content: space-between;

      > div {
        padding: 1.5em 1.5em 0 1.5em;
        width: 50%;

        > div{
          margin-bottom: 1.5em;

          > span{
            display: block;
            font-size: 16px;
          }

          > span:first-child{
            font-size: 14px;
            margin-bottom: 5px;
          }
        }

      }
    }
  }

}

// hotel history page

#hotel-history-page{

  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  background: #F6F7F9;

  #hotel-information{
    margin-top: 4em;
    padding: 2em;
    border-radius: 10px;
    background: #FFF;


    .hotel-info{
      display: flex;
      gap: 2em;
      border: 1px solid #E7E7E7;
      border-radius: 10px;

      #hotel-picture{
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }

      #hotel-name{
        padding: 2em 1em 2em 0;
        #hotel-name-top{
          padding-bottom: 1.5em;
          margin-bottom: 1.5em;
          border-bottom: 1px solid #E7E7E7;
          
          div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:last-child{
              background: #B3FBFF;
              padding: 3px 10px;
              border-radius: 10px;
            }
          }
          div:last-child{
            display: flex;
            gap: 1em;
            align-items: center;
            margin-top: .5em;

            span{
              border-right: 1px solid #E7E7E7;
              padding-right: 1em;
            }
            span:last-child{
              border-right: none;
              padding-right: 0;
            }
          }
        }

        #hotel-name-bottom{
          display: flex;
          gap: 2em;
          // justify-content: space-between;
          #check-in,#check-out{
            display: flex;
            flex-direction: column;
            background: #FBFEFE;
            border: 1px solid #E6EDEE;
            border-radius: 10px;
            padding: 1em;
            gap: .5em;
          }
        }
      }

    }

  }

  #table-room-history{
    thead{
      td {
        padding: 1em;
        background: #F2F2F2;
      }
    }
    tbody{
      td{
        background: #FBFBFB;
        padding: 1.5em;
      }

      tr:last-child{
        td:first-child{
          border-bottom-left-radius: 10px;
        }
        td:last-child{
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-semibold{
  font-weight: 600;
}