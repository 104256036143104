@import './variables.scss';


$danger: $error;
$galago-color: $primary-teal-50;
.btn-light {
  border-color: $galago-color !important;
  color: $galago-color !important;
}

.btn-secondary{
  color: white !important;
}

.continue-btn:disabled{

    background-color: #ADADAD !important;
    border-color: #ADADAD;
  
}

// * {
//   font-family: $font-family-general-sans  !important;
//   font-size: $font-size-16;
//   font-weight: $font-weight-medium !important;
// }