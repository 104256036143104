#sticky-hotel-booking-details {
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 26px;
  border-radius: 12px;
  border: 1px solid var(--primary-primary-100, #016e7f);
  background: var(--white-white-100, #fff);
  /* card-shadow */
  box-shadow: 0px 17px 60px 0px rgba(51, 51, 51, 0.1);

  .sticky-hotel-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    // > p {
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 500;
    //   line-height: 20px;
    //   margin-bottom: unset;
    // }

    > h2 {
      color: #333;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
  }

  .sticky-hotel-image-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;

    > div {
      width: 50%;

      &:first-child {
        > div {
          background-size: cover;
          width: 100%;
          height: 210px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > div {
          &:first-child {
            background-size: cover;
            width: 100%;
            height: 100px;
            border-top-right-radius: 10px;
          }

          &:last-child {
            background-size: cover;
            width: 100%;
            height: 100px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  .sticky-hotel-room-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    > p {
      margin-bottom: unset;
    }

    > p:first-child {
      display: flex;
      width: 100%;

      > span {
        display: block !important;
        width: 100%;
      }
      > span:last-child {
        text-align: right;
      }
    }

    div[data-title='inclusions'] {
      display: flex;
      width: 100%;

      > h2 {
        display: block;
        width: 100%;
        font-size: 18px !important;
        margin-bottom: unset;
      }
      > span:last-child {
        width: 100%;
        text-align: right;
      }
    }
  }

  .sticky-hotel-price-breakdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    width: 100%;

    > p {
      margin-bottom: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .sticky-hotel-total-price {
    border-top: 1px solid #ddd;
    // padding-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    > div:first-child {
      color: #fff;
      font-family: 'Satoshi';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px; /* 156.25% */
      background: #bd0a00;
      width: 100%;
      // padding: 3px 10px; // enable this padding if when the countdown is implemented
    }
  }
}
