@import './_variables';

$teal: #016E7F;
$border: 1px solid #F0F0F0;

#history-tab{
  border-top: $border;
  border-left: $border;
  border-right: $border;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: 'Satoshi', sans-serif;

  ul{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    li{

      a{
        color: #2E2E2E;
      }

      a[data-active="true"]{
        border-bottom: 2px solid $teal;
        color: $teal;
      }

      a:hover{
        color: $teal;
      }
    }
  }
}

#history-pills{
  display: flex;
  padding: 3em 2em;
  border-left: $border;
  border-right: $border;
  font-family: 'Satoshi', sans-serif;
  a{
    padding: 5px 20px;
    border-top: 1px solid $teal;
    border-bottom: 1px solid $teal;
    border-right: 1px solid $teal;
    color: $teal;
    font-weight: 500;
    width: 100px;
    display: block;
    text-align: center;
  }

  a:first-child{
    border-left: 1px solid $teal;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  a:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  a.active{
    background: $teal;
    color: white;
  }

  a:hover{
    background: #E0EEF0;
    color: $teal;
  }

}

#history-list{
  border-right: $border;
  border-left: $border;
  border-bottom: $border;
  padding: 0 2em 2em 2em;
  margin-bottom: 2em;
  font-family: 'Satoshi', sans-serif;

  .history{
    // border: 1px solid #4FA2B4;
    box-shadow: 0px 2px 4px 0px #00000024, 0px 0px 2px 0px #0000001F;
    border-radius: 10px;
    padding: 1.5em;
    margin-bottom: 1em;
    cursor: pointer;
    display: block;
    color: inherit;

    > div{
      height: 50px;
      display: flex;
      align-items: center;
    }

    > div:first-child{
      border-bottom: 1px solid #F0F0F0;
      justify-content: space-between;


      > h6{
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700 !important;
      }

      > span{
        background: #D0F0EF;
        color: #016E7F;
        padding: 3px 10px;
        border-radius: 5px;
      }
    }

    > div:last-child{
      > span {
        display: inline-block;
        margin-right: .5em;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 12px;
      }

      > span.upcoming,
      > span.ongoing{
        background: #BFFAFF;
        color: #016E7F;
      }

      > span.roundtrip{
        color: #fff;
        background: #7FA88A;
      }

      > span.oneway{
        color: #F0F0F0;
        background: #4FA2B4;
      }

      > span.passengers{
        background: #2791B5;
        color: #fff;
      }

      > span.completed{
        background: #ADADAD;
        color: #fff;
      }

      > span.canceled{
        background: #F2DDDC;
        color: #BD0A00;
      }
    }
  }
}

.history-breadcrumb{
  padding: 0;
  margin-top: 2em;
  ul{
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1em;

    li{
      list-style: none;
      font-size: 16px;
      color: #0B5B6C;
      font-weight: 500;

      a{
        color: inherit;
      }
    }
  }
}

.no-booking-yet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    font-family: $font-family-satoshi;
  }
  p {
    font-size: $font-size-16;
    font-weight: $font-weight-medium;
    font-family: $font-family-general-sans;
    margin: 0;
    padding: 0;
    
  }
}