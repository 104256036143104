@import './_variables.scss';



#image-section {
  background-image: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images/homepage/Discover1.png');
  background-position: center;
  background-size: cover;
  height: 200px;
}

.adventure {
  padding: 200px 0;

  .promo-container {
    text-align: center;

    .promo-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 20px;
    }

    .promo-title {
      display: flex;
      flex-direction: column;
      gap: 24px;

      h1 {
        color: $primary-teal-50;
        font-family: $font-family-syne;
        font-weight: $font-weight-bold;
        font-optical-sizing: auto;
      }
      h2 {
        color: $charcoal-60;
        line-height: normal;
      }
    }

    .promo-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      .img-card {
        position: relative;
        .card-ribbon {
          margin: 15px;
          right: 5px;
          padding: 6px 14px;
          background-color: $whitesmoke-10;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 700;
          color: $primary-teal-50;
        }
      }

      .promo-btn {
        padding: 10px 16px;
        font-weight: $font-weight-semibold;
        background-color: $whitesmoke-10;
        font-family: $font-family-general-sans;
        font-size: $font-size-14;
        font-weight: $font-weight-bold;
        color: $primary-teal-50;
        display: flex;
        align-items: center;
        border-radius: 6px;
        &:hover {
          background-color: $primary-teal-50;
          font-weight: $font-weight-semibold;
          color: $whitesmoke-10;
        }
      }

      .promo-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;

        h2 {
          margin: 0;
          font-weight: 700;
        }

        p {
          color: $primary-teal-50;
          font-weight: $font-weight-bold;
          font-family: $font-family-general-sans;
        }
      }
    }
  }
}

#cta {
  padding: 100px 396px;
  background-color: $primary-teal-50;
  color: $whitesmoke-10;
  text-align: center;

  .cta__title {
    font-weight: $font-weight-semibold;
    line-height: normal;
    margin: 0px;
    font-size: $font-size-28;
  }
}

#divider {
  background-image: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/homepage/homepage/new+siargao.png');
  background-position: center;
  object-fit: contain;
  height: 50vh;
  max-height: 25vh;

  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: 34px;
  }
}

.get-ready {
  background-color: $primary-teal-50;
  color: $whitesmoke-10;
  position: relative;
  height: 300px;
  padding: 0 0 0 20rem;

  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: 34px;
  }
  #image-cta {
    flex-direction: column;

    p {
      padding: 0;
      margin: 0;
    }
  }
  img {
    height: 480px;
    width: 481px;
    position: absolute;
    top: -180px;
  }
}

.travel-guides {
  padding: 150px 171px;

  .travel-container {
    text-align: center;

    .travel-title {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 140px;

      h1 {
        color: $primary-teal-50;
        font-family: $font-family-syne;
        font-weight: $font-weight-bold;
        font-optical-sizing: auto;
      }

      h2 {
        color: $charcoal-60;
        line-height: normal;
      }
    }

    .travel-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      .travel-btn {
        background-color: $primary-teal-50;
        color: $whitesmoke-10;
        padding: 18px 80px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .travel-guides-card {
        display: flex;
        flex-direction: row;
        gap: 24px;
        border-bottom: 1px solid $charcoal-40;
        padding-bottom: 100px;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: start;
          gap: 24px;
        }
        .blog__title_homepage{
          color: $charcoal-60;
          font-weight: $font-weight-semibold;
          margin: 0;
          line-height: normal;
        }
        p {
          color: $charcoal-60;
          line-height: 28px;
          padding: 0;
          margin: 0;
          font-weight: 500;
        }
        p:nth-last-child(1) {
          color: $secondary;
          font-size: $font-size-18;
          font-weight: $font-weight-medium;
        }
        a {
          text-decoration: none;
          cursor: pointer;
          color: $primary-teal-50;
          font-weight: $font-weight-medium;

          &:hover {
            color: $secondary-cyan-50;
          }
        }
      }
    }
  }
}

.acc-aff {
  padding: 0 0 200px 0;
  
  .acc-aff-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/homepage/homepage/grainy_bg.png');
    background-position: center;
    background-size: cover;

    background-color: $primary-teal-50;

    color: $whitesmoke-10;
    text-align: center;
    padding: 100px 120px;
    border-radius: 16px;
    gap: 64px;


    h1 {
      font-family: $font-family-syne;
      font-size: $font-size-34;
      font-weight: $font-weight-medium;
      font-style: normal;
      margin: 0;
    }
    .acc-aff-grid {
      display: flex;
      justify-content: center;
      gap: 60px;

      .partner-box {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.scroller-container{
  padding: 150px 0 200px 0;
}

// promo card
.img-card img {
  height: 350px !important;
  width: 286px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 16px;
}
// travel guides

.image {
  object-fit: contain;
}

.image img {
  height: 276px;
  width: 422px;
  border-radius: 16px;
  object-fit: cover;
}

// Media Queries

@media screen and (max-width: 940px) {
  .image img {
    height: auto;
    width: auto;
    border-radius: 16px;
    object-fit: cover;
  }
  .adventure {
    padding: 100px 10px;
    .promo-container {
      .promo-grid {
        .promo-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 14px;
        }
      }
    }
  }

  #cta {
    background-color: $primary-teal-50;
    color: $whitesmoke-10;
    text-align: center;

    h1 {
      font-size: $font-size-28;
      font-weight: $font-weight-bold;
      line-height: 34px;
    }
  }
  .travel-guides {
    padding: 100px 10px;
    .travel-container {
      .travel-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        .travel-guides-card {
          display: flex;
          flex-direction: column;
          object-fit: cover;
          padding: 10px;

          .image img {
            aspect-ratio: 3 / 2;
            object-fit: cover;
            flex: 1 1 300px;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: start;
            gap: 24px;
          }
          h1 {
            font-size: $font-size-18;
            font-weight: $font-weight-bold;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: $font-size-16;
          }
          p:nth-last-child(1) {
            color: $secondary;
            font-size: $font-size-16;
          }

          span {
            color: $primary-teal-50;
            font-weight: $font-weight-medium;
          }
        }
      }
    }
  }
  .acc-aff {
    .acc-aff-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $primary-teal-50;
      color: $whitesmoke-10;
      text-align: center;
      padding: 100px 50px;
      border-radius: 16px;
      gap: 52px;

      h1 {
        font-size: $font-size-32;
        font-weight: $font-weight-medium;
        font-style: normal;
        line-height: 60px;
      }
      .acc-aff-grid {
        display: flex;
        flex-direction: column;
        //   grid-template-columns: repeat(auto-fit, minmax(15rem, 4fr));
        justify-content: center;
        align-items: center;
        object-fit: cover;
        gap: 24px;

        .partner-box {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .adventure {
    .promo-container {
      .promo-title {
        margin-bottom: 34px;

        h1 {
          font-size: $font-size-18;
        }
        h2 {
          font-size: $font-size-16;
        }
      }

      .promo-info {
        h2 {
          font-size: $font-size-18;
        }
        p {
          font-size: $font-size-16;
        }
      }
    }
  }

  .travel-guides {
    .travel-container {
      .travel-title {
        margin-bottom: 34px;

        h1 {
          font-size: $font-size-18;
        }
        h2 {
          font-size: $font-size-16;
        }
      }

      .travel-guides-card {
        .info {
          h1 {
            font-size: $font-size-18;
          }
          p {
            font-size: $font-size-16;
          }
        }

        span {
          display: none;
        }
      }

      .travel-column {
        gap: 80px;
      }
    }
  }

  #cta {
    padding: 64px;
    h1 {
      font-size: $font-size-18;
    }
  }
}

@media screen and (min-width: 768px) {
  .adventure {
    .promo-container {
      .promo-title {
        margin-bottom: 124px;

        h1 {
          font-size: $font-size-34;
        }
        h2 {
          font-size: $font-size-24;
        }
      }

      .promo-info {
        h2 {
          font-size: $font-size-22;
        }
        p {
          font-size: $font-size-17;
        }
       }
    }
  }

  .travel-guides {
    .travel-container {
      .travel-title {
        h1 {
          font-size: $font-size-34;
        }
        h2 {
          font-size: $font-size-24;
        }
      }

      .travel-guides-card {
        .info {
          h1 {
            font-size: $font-size-22;
          }
          p {
            font-size: $font-size-20;
          }
        }
      }

      .travel-column {
        gap: 120px;
      }
    }
  }

  #cta {
    h1 {
      font-size: $font-size-28;
    }
  }
}
