#hotel-map-info {
  > div:first-child {
    width: 100%;
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(1, 110, 127, 0.05);

    #gally-logo-holder {
      padding: 16px;
      border: 5px solid #4fa2b4;
      background: #016e7f;
      border-radius: 100%;
    }

    #gally-logo-text {
      p {
        // font-family: General Sans;
        text-align: justify;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
      p:first-child {
        color: #016e7f;
        font-size: 18px;
      }
      p:last-child {
        color: #333;
        font-size: 16px;
      }
    }
  }

  > div:last-child {
    > div:last-child {
      border-radius: 16px;
      border: 1px solid #eee;
      padding: 40px 24px;
    }
  }
}

// Share Hotel Modal
.btn-style {
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  color: #000;

  &:hover {
    background: #d0f0ef;
    border: 1px solid #016e7f;
    color: #016e7f;
  }
}
