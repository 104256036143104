.signup-modal-header {
  display: flex;
  padding: 34px 32px 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #333;

  h4 {
    font-weight: 600;
    margin: 0;
  }

  span {
    color: #808080;
  }
}

.signup-modal-button-holder {
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: 'General-sans', sans-serif;

  button {
    width: 344px;
    height: 50px;
    flex-shrink: 0;
    border: 1px solid #016e7f;
    color: #016e7f;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;

    > img,
    svg {
      display: block;
    }
  }

  button:hover {
    border: 1px solid #016e7f;
  }
}

#button-break {
  display: flex;
  width: 344px;
  justify-content: center;
  color: #333;

  > span {
    background-color: #fff;
    z-index: 1;
    padding-left: 5px;
    padding-right: 5px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    bottom: 9px;
  }
}

.signup-modal-footer {
  padding: 20px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  font-size: 14px;
  font-family: 'General-sans', sans-serif;
  line-height: 20px;
  font-weight: 500;
  color: #333;
  a {
    text-decoration: none;
    color: #016e7f;
    &:hover {
      color: chocolate;
    }
  }
}
