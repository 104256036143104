@import 'resources/css/variables';
// @import 'resources/css/satoshi.scss';
@import url('https://fonts.cdnfonts.com/css/satoshi');
// @import 'resources/css/general-sans.scss';
@import url('https://fonts.cdnfonts.com/css/general-sans');

@import 'resources/css/nav-tabs.scss';

@import 'resources/css/form-control.scss';
@import 'resources/css/modal.scss';
@import 'resources/css/button.scss';

@import '~bootstrap/scss/bootstrap.scss';
@import '~pretty-checkbox/src/pretty-checkbox.scss';

@import 'leaflet/dist/leaflet.css';

body {
  font-family: 'Satoshi';
  // font-weight: 600;
}

// resets
img,
svg {
  display: unset;
}

// override styles
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Satoshi';
  font-weight: 500;
}

.span-bold {
  font-family: 'Satoshi';
  font-weight: 500;
}

// removes the eye icon in the input password
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// removes the arrow in the input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     /* display: none; <- Crashes Chrome on hover */
//     -webkit-appearance: none;
//     margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
// }

// input[type=number] {
//     -moz-appearance:textfield; /* Firefox */
// }

.satoshi-regular {
  font-family: 'Satoshi';
  font-weight: 400;
}
.satoshi-medium {
  font-family: 'Satoshi';
  font-weight: 500;
}

.satoshi-semibold {
  font-family: 'Satoshi';
  font-weight: 600;
}

.satoshi-bold {
  font-family: 'Satoshi';
  font-weight: 900;
}

.generalsans-regular {
  font-family: 'General Sans';
  font-weight: 400;
}
.generalsans-medium {
  font-family: 'General Sans';
  font-weight: 500;
}
.generalsans-semibold {
  font-family: 'General Sans';
  font-weight: 600;
}
.generalsans-bold {
  font-family: 'General Sans';
  font-weight: 900;
}

.btn {
  font-weight: 400;
  border-radius: 12px;
}

.btn-sm {
  padding: 8px 18px;
}

.btn-lg {
  font-size: 16px;
  font-weight: 400;
}

.btn-disabled {
  background: var(--color-shades-grey-900, #adadad);
  box-shadow: 1.9500000476837158px 1.9500000476837158px 2.5999999046325684px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
}

.btn-outline-primary:hover {
  background-color: transparent;
}

.galago-modal-header {
  color: #414141;
  font-family: 'Satoshi';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.breadcrumb {
  .breadcrumb-item,
  .breadcrumb-item > a {
    color: #fff;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    // content: '>';
    content: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images/BreadCrumbChevronRight.svg');
    color: #fff;
  }
}

.textColor {
  color: #333;
}
