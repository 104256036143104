.scroller {
  max-width: 100%;
  min-width: 375px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.scroller__inner img {
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.scroller[data-animated='true'] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, white 10%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 10%, white 80%, transparent);
}

.scroller[data-animated='true'] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction='left'] {
  --_animation-direction: reverse;
}

.scroller[data-direction='right'] {
  --_animation-direction: forwards;
}

.scroller[data-speed='fast'] {
  --_animation-duration: 20s;
}

.scroller[data-speed='slow'] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
@media screen and (max-width: 940px) {
  .scroller {
    max-width: 750px;
  }
}
