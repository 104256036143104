$small: 475px;
$medium: 786px;
$large: 1024px;



#airport-location-holder {
  > div:first-child,
  > div:last-child {
    position: relative;

    .clear-text-svg {
      position: absolute !important;
      z-index: 1;
      bottom: 17px;
      right: 10px;
      cursor: pointer;
    }
  }
}

.no-link-style {
  text-decoration: none;
  color: #016e7f;
}

.no-link-style:hover,
.no-link-style:active {
  color: white; /* Change text color to white on hover and active states */
}

.flight-calendar-holder {
  position: absolute;
  z-index: 1;
  width: 350px;
}

.tab_container {
  padding: 22px 20px;
}
.svg-arrow {
  align-self: last baseline;
}
.departure-column {
  display: flex;
  gap: 10px;

  @media screen and (max-width: $medium) {
    flex-direction: column;
    margin-top: 20px;
  }
}
.switch {
  @media screen and (max-width: $medium) {
    display: none;
  }
}
.flying {
  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
  }
}
