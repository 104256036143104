html {
  overflow-x: hidden;
}

table {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;

  td {
    padding: 0;
  }

  // tr:last-of-type {
  //   background-color: red !important;
  //   border-bottom-left-radius: 6px;
  //   border-bottom-right-radius: 6px;
  // }
}

.table-room-card:last-of-type {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: 0 0 0 1px #f5f5f5;
  td {
    border-bottom: none;
  }

  tr {
    border-bottom: none;
  }

  tr td:first-child {
    border-left: none;
  }

  tr td:last-child {
    border-right: none;
  }
}
.even-row {
  background-color: #f5f5f5;
}

.odd-row {
  background-color: #fcfcfc;
}

.available-rooms-content {
  //   background-color: #f5f5f5;
}
.available-rooms-title {
  text-align: center;
  background-color: #e7eaeb;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;

  td {
    padding: 14px 10px;
    border: none;
  }
}

.rooms {
  padding: 24px 20px 24px 24px;
  max-width: 300px;
  width: 25%;
  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1rem;
  }
  li {
    list-style: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.sleeps {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 10px;
  height: 100%;
  min-height: 119px;
}

td {
  border: 1px solid #e7eaeb;
  margin: 0;
}

.choice {
  //   max-width: 284px;
  width: 100%;
  //   border: 1px solid #e7eaeb;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  min-height: 119px;
  height: 100%;
}

.refundable {
  color: #217c38;
}
.non-refund {
  color: #bd0a00;
}

.price {
  //   border: 1px solid #e7eaeb;
  min-height: 119px;
  display: flex;
  justify-content: center;
  gap: 3rem;
  text-align: right;
  align-items: center;
  width: 100%;
  .fees {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 0;
    padding-top: 0;

    .taxes-and-fees {
      margin-top: 0;
    }
  }

  .reserve-btn {
    background-color: #3f8290;
  }
  .reserve-btn:disabled {
    background-color: #adadad;
    border-color: #adadad;
  }
  .reserve-btn:hover {
    background-color: #016372;
  }

  .room-price-tooltip {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 50%;
    left: -90px;
    max-width: 348px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #c8e2e8;
    overflow: hidden;
    z-index: 90;
    gap: 16px;

    .room-price-row {
      padding: 0 14px;
      display: flex;

      justify-content: space-between;

      #night-small {
        font-size: 13px;
      }
    }

    .room-price-row:first-child {
      padding-top: 20px;
    }

    .total-price {
      display: flex;
      justify-content: space-between;
      background-color: #f5feff;
      padding: 20px 14px;
    }
  }
}

.room-filters {
  padding-bottom: 60px;
  display: flex;
  font-size: 14px;
  gap: 1rem;
  color: #016e7f;
  font-weight: 500;
  // position: relative;

  > div:not(:last-child) {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #016e7f;
    transition: 0.1s ease-in-out;
  }

  > div:not(:last-child):hover {
    background-color: #e6f1f2;
    transition: 0.1s ease-in-out;
  }

  .active {
    background-color: #e6f1f2;
  }

  .bed-preferences-filters {
    padding: 12px 20px;
    position: relative;
  }
  .bed-preferences-dropdown {
    background-color: #ffffff;
    position: absolute;
    top: 160px;
    left: 11px;
    padding: 10px 14px;
    border: 1px solid #016e7f;
    border-radius: 12px;
    width: 100%;
    max-width: 312px;
    max-height: 246px;
    scroll-behavior: smooth;
    overflow-y: auto;
    box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
    scrollbar-color: #adadad transparent;

    label {
      font-size: 14px;
    }

    input {
      width: 20px;
      height: 20px;
    }
  }

  .meal-type-filters {
    padding: 12px 20px;
  }

  .meal-type-dropdown {
    background-color: #ffffff;
    position: absolute;
    top: 160px;
    left: 175px;
    padding: 10px 14px;
    border: 1px solid #016e7f;
    border-radius: 12px;
    width: 100%;
    max-width: 312px;
    max-height: 246px;
    scroll-behavior: smooth;
    overflow-y: auto;
    box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
    scrollbar-color: #adadad transparent;

    label {
      font-size: 14px;
    }

    input {
      width: 20px;
      height: 20px;
    }
  }

  .facilities-filters {
    padding: 12px 20px;
  }

  .facilities-dropdown {
    background-color: #ffffff;
    position: absolute;
    top: 160px;
    left: 300px;
    padding: 10px 14px;
    border: 1px solid #016e7f;
    border-radius: 12px;
    width: 100%;
    max-width: 312px;
    max-height: 246px;
    scroll-behavior: smooth;
    overflow-y: auto;
    box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
    scrollbar-color: #adadad transparent;

    label {
      font-size: 14px;
    }

    input {
      width: 20px;
      height: 20px;
    }
  }
  .room-filtes-btn {
    font-size: 14px;
  }
}
