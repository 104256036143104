// fonts

//Media Query Values
$small: 480px;
$medium: 768px;
$large: 1024px;

.general {
  font-family: 'General Sans', sans-serif;
}
.satoshi {
  font-family: 'Satoshi', sans-serif;
}
.backToBlogs {
  margin: auto;
  max-width: 1080px;
}

.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.italic {
  font-style: italic;
}
.blog-hero-section {
  background-image: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images/blog+header+v2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 6px;

  .head-text {
    font-family: 'Syne', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
  }

  .tagline {
    font-family: 'General-Sans', sans-serif;
    font-size: 17px;
    font-weight: 500px;
    line-height: normal;
    margin-bottom: 14px;
  }

  .search-container {
    position: relative;

    .search-input {
      padding: 14px 14px 14px 42px;
      border-radius: 22px;
      color: #333333;
      font-family: 'General-Sans', sans-serif;
      font-size: 18px;
      font-weight: 500;
      box-sizing: border-box;
      border: 2px solid #d9d9d9;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &:focus {
        outline-color: #016e7f;
      }

      @media screen and (min-width: 0) and (max-width: $medium) {
        width: 100%;
      }
      @media screen and (min-width: $medium) {
        width: 35vw;
      }
    }
    .search-icon {
      top: 9px;
      left: 10px;
      padding: 4px 5px;
    }
  }
}
.featured-blogs {
  .container {
    padding: 20px 0 0 0;

    .text-secondary {
      font-family: 'General-sans', sans-serif;
      line-height: normal;
      font-weight: 500;
    }

    .blog-title {
      padding: 10px 0px 14px 0px;
      @media screen and (min-width: 0) and (max-width: $medium) {
        font-size: 18px;
      }
    }

    .separator {
      margin: 20px 0;
      height: 2px;
      background: #d9d9d9;
    }
  }

  .feature-header-text {
    font-family: 'General-sans', sans-serif;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    padding: 100px 0 64px 0px;
  }

  .blog-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    @media screen and (min-width: 0) and (max-width: $medium) {
      gap: 64px;
    }
    @media screen and (min-width: $medium) {
      gap: 42px;
    }
  }
}

//------------ Start of Migs: New Blogs CSS Format ----------------------------------------------

//Hero Section
.hero_sec {
  position: relative;
  display: flex;

  img {
    flex: 1;
    height: 300px;
    object-fit: cover;
    background-position: center center;
  }

  .gradient_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(92deg, #016e7f -11.76%, rgba(1, 110, 127, 0) 108.09%);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (min-width: 0) and (max-width: $medium) {
      gap: 50px;
    }
    @media screen and (min-width: $medium) {
      gap: 70px;
    }

    h1 {
      color: white;
      text-align: center;
      margin: 0px;

      font-family: 'Satoshi', sans-serif;
      font-weight: 500;
      @media screen and (min-width: 0) and (max-width: $medium) {
        font-size: 24px;
      }
      @media screen and (min-width: $medium) {
        font-size: 32px;
      }
    }

    .bread_crumbs {
      display: flex;
      align-items: center;

      @media screen and (min-width: 0) and (max-width: $medium) {
        padding: 14px 24px;
      }
      @media screen and (min-width: $medium) {
        padding: 12px 104px;
      }

      a {
        color: white;
        cursor: pointer;
        text-decoration: none;
        font-family: 'General Sans', sans-serif;

        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 14px;
        }
        @media screen and (min-width: $medium) {
          font-size: 16px;
        }

        &:hover {
          color: #bffaff;
        }
      }
    }
  }
}

// article Body

.articleBody {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  gap: 47px;

  //Phones and Tablets
  @media screen and (min-width: 0) and (max-width: $medium) {
    padding: 64px 0;
  }

  //Desktops
  @media screen and (min-width: $medium) and (max-width: $large) {
    padding: 200px 0;
  }

  //Large Devices
  @media screen and (min-width: $large) {
    padding: 200px 0;
  }

  .articleHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      color: #333333;
      margin: 0;
      font-weight: 700;
      font-family: 'Satoshi', sans-serif;

      @media screen and (min-width: 0) and (max-width: $medium) {
        font-size: 24px;
      }
      @media screen and (min-width: $medium) {
        font-size: 34px;
      }
    }

    .info {
      display: flex;
      gap: 6px;
      font-weight: 500;
      font-family: 'General Sans';

      .authorSec,
      .articleDate,
      .article-read {
        color: #333333;
        margin: 0;
      }

      .separator {
        color: #adadad;
        margin: 0;
      }

      .authorSec,
      .articleDate,
      .article-read,
      .separator {
        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 13px;
        }
        @media screen and (min-width: $medium) and (max-width: $large) {
          font-size: 16px;
        }
        @media screen and (min-width: $large) {
          font-size: 16px;
        }
      }
    }

    .shareBtnContainer {
      margin-top: 14px;
      display: flex;
      flex-direction: row;
      gap: 20px;

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 100px;

    .articleDescription {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        color: #333333;
        font-family: 'General Sans';
        font-weight: 500;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          text-align: start;
          font-size: 14px;
        }
        @media screen and (min-width: $medium) {
          text-align: justify;
          font-size: 17px;
        }

        span {
          color: #4fa2b4;
          font-style: italic;
        }
      }
    }

    .notice_container {
      display: flex;
      flex-direction: column;
      gap: 47px;

      .notice {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #f3fbfb;
        padding: 16px 24px;
        border-left: 4px solid #016e7f;
        gap: 6px;

        p {
          color: #333333;
          margin: 0;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 16px;
          }
        }
      }

      .noticeDescription {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }

          span {
            color: #4fa2b4;
            font-style: italic;
          }
        }
      }
    }

    .storieswithTitleImageParagraphs {
      display: flex;
      flex-direction: column;
      gap: 64px;

      .head {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h2 {
          font-size: 24px;
          color: #333333;
          font-family: 'Satoshi', sans-serif;
          font-weight: 700;
          margin: 0px;
        }

        .head_desc {
          display: flex;
          flex-direction: column;
          gap: 16px;

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }
        }
      }

      .story {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 0) and (max-width: $medium) {
          gap: 24px;
        }
        @media screen and (min-width: $medium) {
          gap: 32px;
        }

        .head {
          display: flex;
          flex-direction: column;
          gap: 16px;

          h2 {
            font-size: 20px;
            color: #333333;
            font-family: 'Satoshi', sans-serif;
            font-weight: 700;
            margin: 0px;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }

          .img_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            img {
              max-width: 100%;
              max-height: 500px;
              border-radius: 4px;
              align-self: stretch;

              object-fit: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }

            p {
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              font-family: 'General Sans';
              color: #adadad;
              margin: 0;
            }
          }
        }

        .title {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h2 {
            color: #333333;
            font-family: 'General Sans';
            font-size: 20px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;
            font-style: italic;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }
          }
        }

        .info-grp {
          display: flex;
          flex-direction: column;
          gap: 24px;

          h3 {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }

            span {
              color: #4fa2b4;
              font-style: italic;
              font-weight: 500;
            }
          }
        }

        .bullet_grp {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .bullet_list {
            gap: 16px;

            p {
              font-weight: 500;
              color: #333333;
              font-family: 'General Sans';

              &:first-child {
                margin: 0 0 16px 0;
              }
              &:last-child {
                margin: 16px 0 0 0;
              }
              @media screen and (min-width: 0) and (max-width: $medium) {
                text-align: start;
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                text-align: justify;
                font-size: 17px;
              }
            }

            ul {
              padding: 0 0 0 24px;
              margin: 0;

              li {
                font-weight: 500;
                list-style-type: disc;
                font-family: 'General Sans';
                color: #333333;
                padding: 0 0 8px 0;

                @media screen and (min-width: 0) and (max-width: $medium) {
                  text-align: start;
                  font-size: 14px;
                }
                @media screen and (min-width: $medium) {
                  text-align: justify;
                  font-size: 17px;
                }

                span {
                  color: #4fa2b4;
                  font-style: italic;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .galaGuide {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          background: #f3fbfb;
          padding: 16px 24px;
          border-left: 4px solid #016e7f;
          gap: 6px;

          h3 {
            font-size: 17px;
            font-weight: 800;
            color: #333333;
            font-family: 'General Sans';
            margin: 0;
          }

          p {
            color: #333333;
            margin: 0;
            font-family: 'General Sans';
            font-weight: 500;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 16px;
            }

            a {
              cursor: pointer;
              color: #016e7f;

              &:hover {
                color: #4fa2b4;
              }
            }
          }
        }

        .tip {
          display: flex;
          flex-direction: column;
          gap: 6px;

          h3 {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
            }
          }

          span {
            font-family: 'General Sans';
            font-size: 17px;
            font-style: italic;
            font-weight: 500;
            color: #4fa2b4;
          }
        }
      }
    }

    .storywithTitleImageParagraph {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 0) and (max-width: $medium) {
        gap: 24px;
      }
      @media screen and (min-width: $medium) {
        gap: 32px;
      }

      .head {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h2 {
          color: #333333;
          font-family: 'Satoshi', sans-serif;
          font-weight: 700;
          margin: 0px;

          @media screen and (min-width: 0) and (max-width: $medium) {
            font-size: 20px;
          }
          @media screen and (min-width: $medium) {
            font-size: 24px;
          }
        }

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
          }
        }

        .img_container {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            max-width: 100%;
            max-height: 500px;
            overflow: hidden;

            align-self: stretch;
            border-radius: 4px;
            object-fit: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          p {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            font-family: 'General Sans';
            color: #adadad;
            margin: 0;
          }
        }
      }

      .info_grp {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }

        span {
          font-family: 'General Sans';
          font-style: italic;
          font-weight: 500;
          color: #4fa2b4;
        }

        .bullet_grp {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .title {
            font-family: 'General Sans';
            font-weight: 500;
            font-size: 17px;
            color: #333333;
          }

          .bullet_list {
            .title {
              color: #333333;
              font-family: 'General Sans';
              font-weight: 600;
              margin: 0;

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }
            }

            .title_desc {
              color: #333333;
              font-family: 'General Sans';
              margin: 0 0 16px 0;

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              span {
                color: #4fa2b4;
                font-family: 'General Sans';
                font-style: italic;
                font-weight: 500;
              }
            }

            p {
              font-weight: 500;
              color: #333333;
              font-family: 'General Sans';
              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              &:first-child {
                margin: 0 0 16px 0;
              }
              &:last-child {
                margin: 16px 0 0 0;
              }
            }

            ul {
              padding: 0 0 0 24px;
              margin: 0;

              li {
                font-weight: 500;
                list-style-type: disc;
                font-family: 'General Sans';
                color: #333333;
                padding: 0 0 8px 0;
                @media screen and (min-width: 0) and (max-width: $medium) {
                  font-size: 14px;
                }
                @media screen and (min-width: $medium) {
                  font-size: 17px;
                }

                &:last-child {
                  padding: 0;
                }

                span {
                  color: #4fa2b4;
                  font-style: italic;
                  font-weight: 500;
                }
              }
            }

            ol {
              padding: 0 0 0 24px;
              margin: 0;

              li {
                font-weight: 500;
                list-style-type: decimal;
                font-family: 'General Sans';
                font-size: 17px;
                color: #333333;
                padding: 0 0 8px 4px;

                span {
                  color: #4fa2b4;
                  font-style: italic;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .galaGuide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #f3fbfb;
        padding: 16px 24px;
        border-left: 4px solid #016e7f;
        gap: 6px;

        h3 {
          font-size: 17px;
          font-weight: 800;
          color: #333333;
          font-family: 'General Sans';
          margin: 0;
        }

        p {
          color: #333333;
          margin: 0;
          font-family: 'General Sans';
          font-weight: 500;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            font-size: 16px;
          }

          a {
            cursor: pointer;
            color: #016e7f;

            &:hover {
              color: #4fa2b4;
            }
          }
        }
      }

      .subtitle {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-size: 17px;
          color: #333333;
          font-family: 'General Sans';
          font-weight: 700;
          margin: 0px;
        }

        p {
          color: #333333;
          font-family: 'General Sans';
          font-style: italic;
          font-weight: 500;
          margin: 0px;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            font-size: 16px;
          }
        }
      }

      .img_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        img {
          max-width: 100%;
          max-height: 500px;
          border-radius: 4px;
          align-self: stretch;

          object-fit: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        p {
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          font-family: 'General Sans';
          color: #adadad;
          margin: 0;
        }
      }
    }

    .img_container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      img {
        //To Update
        max-width: 100%;
        max-height: 500px;
        overflow: hidden;

        align-self: stretch;
        border-radius: 4px;
        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      p {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        font-family: 'General Sans';
        color: #adadad;
        margin: 0;
      }
    }

    .textual_story {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h2 {
        color: #333333;
        font-family: 'Satoshi', sans-serif;
        font-weight: 700;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 20px;
        }
        @media screen and (min-width: $medium) {
          font-size: 24px;
        }
      }

      p {
        color: #333333;
        font-family: 'General Sans';
        font-weight: 500;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          text-align: start;
          font-size: 14px;
        }
        @media screen and (min-width: $medium) {
          text-align: justify;
          font-size: 17px;
        }
      }

      span {
        font-family: 'General Sans';
        font-style: italic;
        font-weight: 500;
        color: #4fa2b4;
      }
    }

    .extensive_story {
      display: flex;
      flex-direction: column;
      gap: 32px;

      h2 {
        color: #333333;
        font-family: 'Satoshi', sans-serif;
        font-weight: 700;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 20px;
        }
        @media screen and (min-width: $medium) {
          font-size: 24px;
        }
      }

      p {
        color: #333333;
        font-family: 'General Sans';
        font-weight: 500;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          text-align: start;
          font-size: 14px;
        }
        @media screen and (min-width: $medium) {
          text-align: justify;
          font-size: 17px;
        }
      }

      span {
        font-family: 'General Sans';
        font-style: italic;
        font-weight: 500;
        color: #4fa2b4;

        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 14px;
        }
        @media screen and (min-width: $medium) {
          font-size: 17px;
        }
      }

      .info_grp {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }
      }

      .galaGuide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #f3fbfb;
        padding: 16px 24px;
        border-left: 4px solid #016e7f;
        gap: 6px;

        h3 {
          font-size: 17px;
          font-weight: 800;
          color: #333333;
          font-family: 'General Sans';
          margin: 0;
        }

        p {
          color: #333333;
          margin: 0;
          font-family: 'General Sans';
          font-weight: 500;

          @media screen and (min-width: 0) and (max-width: $medium) {
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            font-size: 16px;
          }

          a {
            cursor: pointer;
            color: #016e7f;

            &:hover {
              color: #4fa2b4;
            }
          }
        }
      }

      .bullet_grp {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          font-family: 'General Sans';
          font-weight: 500;
          color: #333333;

          @media screen and (min-width: 0) and (max-width: $medium) {
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            font-size: 17px;
          }
        }

        .bullet_list {
          .title {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 600;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }
          }

          .title_desc {
            color: #333333;
            font-family: 'General Sans';
            margin: 0 0 16px 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }

            span {
              color: #4fa2b4;
              font-family: 'General Sans';
              font-style: italic;
              font-weight: 500;
            }
          }

          p {
            font-weight: 500;
            color: #333333;
            font-family: 'General Sans';

            &:first-child {
              margin: 0 0 16px 0;
            }
            &:last-child {
              margin: 16px 0 0 0;
            }

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }
          }

          ul {
            padding: 0 0 0 24px;
            margin: 0;

            li {
              font-weight: 500;
              list-style-type: disc;
              font-family: 'General Sans';
              color: #333333;
              padding: 0 0 8px 0;

              &:last-child {
                padding: 0;
              }

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              span {
                color: #4fa2b4;
                font-style: italic;
                font-weight: 500;
              }
            }
          }

          ol {
            padding: 0 0 0 24px;
            margin: 0;

            li {
              font-weight: 500;
              list-style-type: decimal;
              font-family: 'General Sans';
              color: #333333;
              padding: 0 0 8px 4px;

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              span {
                color: #4fa2b4;
                font-style: italic;
                font-weight: 500;
              }
            }
          }
        }

        .numbered_list {
          .title {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }

          .title_desc {
            color: #333333;
            font-family: 'General Sans';
            margin: 0 0 16px 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }

            span {
              color: #4fa2b4;
              font-family: 'General Sans';
              font-style: italic;
              font-weight: 500;
            }
          }

          p {
            font-weight: 500;
            color: #333333;
            font-family: 'General Sans';

            &:first-child {
              margin: 0 0 16px 0;
            }
            &:last-child {
              margin: 16px 0 0 0;
            }

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }
          }

          ul {
            padding: 0 0 0 24px;
            margin: 0;

            li {
              font-weight: 500;
              list-style-type: decimal;
              font-family: 'General Sans';
              color: #333333;
              padding: 0 0 8px 0;

              &:last-child {
                padding: 0;
              }

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              span {
                color: #4fa2b4;
                font-style: italic;
                font-weight: 500;
              }
            }
          }

          ol {
            padding: 0 0 0 24px;
            margin: 0;

            li {
              font-weight: 500;
              list-style-type: decimal;
              font-family: 'General Sans';
              color: #333333;
              padding: 0 0 8px 4px;

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
              }

              span {
                color: #4fa2b4;
                font-style: italic;
                font-weight: 500;
              }
            }
          }
        }
      }

      .sub_content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .head {
          display: flex;
          flex-direction: column;
          gap: 16px;

          h3 {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
            }
          }
        }
      }

      .tip_container {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .tip {
          display: flex;
          flex-direction: column;
          gap: 16px;

          h3 {
            color: #333333;
            font-family: 'General Sans';
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }
        }
      }

      .tip {
        display: flex;
        flex-direction: column;
        gap: 6px;

        h3 {
          color: #333333;
          font-family: 'General Sans';
          font-size: 17px;
          font-weight: 600;
          margin: 0;
        }

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }

        span {
          font-family: 'General Sans';
          font-style: italic;
          font-weight: 500;
          color: #4fa2b4;
        }
      }

      .requirement {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          font-family: 'General Sans';
          font-size: 17px;
          margin: 0;
          color: #333333;
          font-weight: 600;
        }

        .title_desc {
          font-family: 'General Sans';
          margin: 0;
          color: #333333;
          font-weight: 500;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }

          span {
            color: #4fa2b4;
            font-family: 'General Sans';
            font-style: italic;
            font-weight: 500;
          }
        }
      }

      .checklist_container {
        display: flex;
        flex-direction: column;
        gap: 42px;

        .item {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .head {
            display: flex;
            flex-direction: column;
            gap: 16px;

            h3 {
              color: #333333;
              font-family: 'General Sans';
              font-size: 17px;
              font-weight: 600;
              margin: 0;
            }

            p {
              color: #333333;
              font-family: 'General Sans';
              font-weight: 500;
              margin: 0;

              @media screen and (min-width: 0) and (max-width: $medium) {
                text-align: start;
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                text-align: justify;
                font-size: 17px;
              }
            }
          }
        }
      }
    }

    .hotel_container {
      display: flex;
      flex-direction: column;
      gap: 64px;

      .head {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h2 {
          color: #333333;
          font-family: 'Satoshi', sans-serif;
          font-weight: 700;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            font-size: 20px;
          }
          @media screen and (min-width: $medium) {
            font-size: 24px;
          }
        }

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }
      }

      .hotel_details {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .contacts {
          display: flex;
          flex-direction: column;
          gap: 6px;

          h2 {
            color: #333333;
            font-family: 'Satoshi', sans-serif;
            font-size: 17px;
            font-weight: 800;
            margin: 0;
          }

          p {
            color: #333333;
            font-family: 'General Sans';
            font-style: italic;
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }
        }

        .img_container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;

          img {
            max-width: 100%;
            max-height: 500px;
            border-radius: 4px;
            align-self: stretch;

            object-fit: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          p {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            font-family: 'General Sans';
            color: #adadad;
            margin: 0;
          }
        }

        .info_grp {
          display: flex;
          flex-direction: column;
          gap: 16px;

          p {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }

          .bullet_grp {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .bullet_list {
              gap: 16px;

              p {
                font-weight: 500;
                color: #333333;
                font-family: 'General Sans';

                @media screen and (min-width: 0) and (max-width: $medium) {
                  font-size: 14px;
                }
                @media screen and (min-width: $medium) {
                  font-size: 17px;
                }

                &:first-child {
                  margin: 0 0 16px 0;
                }
                &:last-child {
                  margin: 16px 0 0 0;
                }
              }

              .list_title {
                font-family: 'General Sans';
                font-size: 17px;
                font-weight: 600;
                margin-bottom: 10px;
              }

              ul {
                padding: 0 0 0 24px;
                margin: 0;

                li {
                  font-weight: 500;
                  list-style-type: disc;
                  font-family: 'General Sans';
                  color: #333333;
                  padding: 0 0 8px 0;

                  @media screen and (min-width: 0) and (max-width: $medium) {
                    font-size: 14px;
                  }
                  @media screen and (min-width: $medium) {
                    font-size: 17px;
                  }

                  span {
                    color: #4fa2b4;
                    font-style: italic;
                    font-weight: 500;
                  }
                }
              }
            }
          }

          span {
            color: #4fa2b4;
            font-style: italic;
            font-weight: 500;
          }
        }
      }
    }

    .footnote {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title_container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h2 {
          font-size: 24px;
          color: #333333;
          font-family: 'Satoshi', sans-serif;
          font-weight: 700;
          margin: 0;
        }

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }

        .img_container {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            //To Update
            max-width: 100%;
            max-height: 500px;
            overflow: hidden;

            align-self: stretch;
            border-radius: 4px;
            object-fit: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          p {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            font-family: 'General Sans';
            color: #adadad;
            margin: 0;
          }
        }

        ul {
          padding: 0 0 0 24px;
          margin: 0;

          li {
            font-weight: 500;
            list-style-type: disc;
            font-family: 'General Sans';
            color: #333333;
            padding: 0 0 8px 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
            }

            &:last-child {
              padding: 0;
            }

            span {
              color: #4fa2b4;
              font-style: italic;
              font-weight: 500;
            }
          }
        }

        a {
          text-decoration: underline;
          color: #016e7f;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          color: #333333;
          font-family: 'General Sans';
          font-weight: 500;
          margin: 0;

          @media screen and (min-width: 0) and (max-width: $medium) {
            text-align: start;
            font-size: 14px;
          }
          @media screen and (min-width: $medium) {
            text-align: justify;
            font-size: 17px;
          }
        }

        .bullet_grp {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .bullet_list {
            p {
              &:first-child {
                margin: 0 0 16px 0;
              }
              &:last-child {
                margin: 16px 0 0 0;
              }
            }

            ul {
              padding: 0 0 0 24px;
              margin: 0;

              li {
                font-weight: 500;
                list-style-type: disc;
                font-family: 'General Sans';
                color: #333333;
                padding: 2px 0;

                @media screen and (min-width: 0) and (max-width: $medium) {
                  font-size: 14px;
                }
                @media screen and (min-width: $medium) {
                  font-size: 17px;
                }
              }
            }
          }
        }

        span {
          color: #4fa2b4;
          font-style: italic;
          font-weight: 500;
        }
      }
    }

    .TableContainer {
      display: flex;
      flex-direction: column;
      gap: 32px;

      h2 {
        color: #333333;
        font-family: 'Satoshi', sans-serif;
        font-weight: 700;
        margin: 0;

        @media screen and (min-width: 0) and (max-width: $medium) {
          font-size: 20px;
        }
        @media screen and (min-width: $medium) {
          font-size: 24px;
        }
      }

      table {
        width: 100%;
        border-bottom: 1px solid #e7eaeb;
        tr {
          th {
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            background-color: rgba(1, 110, 127, 0.08);
            border: 1px solid #e7eaeb;

            @media screen and (min-width: 0) and (max-width: $medium) {
              font-size: 14px;
              padding: 14px;
            }
            @media screen and (min-width: $medium) {
              font-size: 17px;
              padding: 14px;
            }
          }

          &:nth-child(n + 2) {
            td {
              border-left: 1px solid #e7eaeb;
              border-right: 1px solid #e7eaeb;
              font-family: 'General Sans';
              color: #333333;
              font-weight: 400;

              @media screen and (min-width: 0) and (max-width: $medium) {
                font-size: 14px;
                padding: 14px;
              }
              @media screen and (min-width: $medium) {
                font-size: 17px;
                padding: 14px;
              }
            }
          }
        }
      }
    }
  }

  hr {
    border: none;
    height: 2px;
    background-color: #b4b4b8;
  }

  .featured_blogs {
    display: flex;
    flex-direction: column;
    gap: 42px;

    h2 {
      font-size: 24px;
      color: #333333;
      font-family: 'Satoshi', sans-serif;
      font-weight: 700;
      margin: 0;
    }

    .card_container {
      display: flex;
      overflow: hidden;

      @media screen and (min-width: 0) and (max-width: $medium) {
        flex-direction: column;
        gap: 42px;
      }
      @media screen and (min-width: $medium) {
        flex-direction: row;
        gap: 20px;
      }

      .blog_card {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .img_container {
          position: relative;

          img {
            max-height: 300px;
            width: 95%;

            object-fit: cover;
            border-radius: 20px;
            background-position: center center;
            background-repeat: no-repeat;
          }

          .badge {
            position: absolute;
            background: white;
            padding: 4px 12px;
            border-radius: 32px;
            box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25);

            @media screen and (min-width: 0) and (max-width: $medium) {
              left: 14px;
              top: 14px;
            }
            @media screen and (min-width: $medium) {
              left: 24px;
              top: 24px;
            }

            h2 {
              font-family: 'Satoshi', sans-serif;
              color: #016e7f;
              font-weight: 700;

              @media screen and (min-width: 0) and (max-width: $medium) {
                text-align: start;
                font-size: 12px;
              }
              @media screen and (min-width: $medium) {
                text-align: justify;
                font-size: 17px;
              }
            }
          }
        }

        .info_grp {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .detail {
            display: flex;
            flex-direction: row;
            gap: 6px;

            p {
              font-family: 'General Sans';
              font-weight: 500;
              margin: 0;
              color: #adadad;

              @media screen and (min-width: 0) and (max-width: $medium) {
                text-align: start;
                font-size: 14px;
              }
              @media screen and (min-width: $medium) {
                text-align: justify;
                font-size: 17px;
              }
            }
          }

          h3 {
            font-family: 'General Sans';
            font-size: 20px;
            font-style: normal;
            color: #333333;
            font-weight: 600;
            margin: 0;

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 16px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 20px;
            }
          }

          .read_article_btn {
            text-decoration: none;
            color: #333333;
            font-family: 'General Sans';
            font-weight: 500;
            &:hover {
              color: #333333;
            }

            .read_btn {
              display: flex;
              flex-direction: row;
              gap: 6px;
              align-items: center;

              p {
                margin: 0;
                font-family: 'General Sans';
                font-weight: 500;

                @media screen and (min-width: 0) and (max-width: $medium) {
                  text-align: start;
                  font-size: 14px;
                }
                @media screen and (min-width: $medium) {
                  text-align: justify;
                  font-size: 17px;
                }
              }
            }

            @media screen and (min-width: 0) and (max-width: $medium) {
              text-align: start;
              font-size: 14px;
            }
            @media screen and (min-width: $medium) {
              text-align: justify;
              font-size: 17px;
            }
          }
        }

        &:hover {
          .img_container {
            img {
              box-shadow: 0px 2px 4px 1px rgba(1, 110, 127, 0.2);
            }
          }

          .info_grp {
            .detail {
              p {
                color: #333333;
              }
            }

            h3 {
              color: #016e7f;
            }

            .read_article_btn {
              .read_btn {
                p {
                  color: #016e7f;
                }
              }
            }
          }
        }
      }
    }
  }
}

//----------------------------------------------------- End of Migs: New Blogs CSS Format ----------------------------------------

// tags

.tags div {
  padding: 4px 21px;

  border-radius: 32px;
}
.blog-title {
  font-weight: 600;
  line-height: normal;
  color: #333333;

  @media screen and (min-width: 0) and (max-width: $medium) {
    font-size: 18px;
  }
  @media screen and (min-width: $medium) {
    text-align: start;
    font-size: 22px;
  }

  &:hover {
    color: #016e7f;
    cursor: pointer;
  }
}

// .trend {
//   background-color: rgba(235, 83, 75, 0.15);
//   border: #eb534b 1px solid;
// }
// .new {
//   background-color: rgba(252, 143, 58, 0.1);
//   border: #fc8f3a 1px solid;
// }
// .travel_guide {
//   background-color: rgba(1, 110, 127, 0.1);
//   border: #016e7f 1px solid;
// }
// .bohol {
//   background-color: rgba(1, 110, 127, 0.1);
//   border: #016e7f 1px solid;
// }

// BlogNavBar Styles

/* Add a black background color to the top navigation */
.topnav {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (min-width: 0) and (max-width: $medium) {
    justify-content: space-between;
    padding-top: 64px;
  }
  @media screen and (min-width: $medium) {
    gap: 14px;
    padding-top: 100px;
  }
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #adadad;
  text-align: center;
  line-height: normal;
  text-decoration: none;
  font-weight: 500;

  @media screen and (min-width: 0) and (max-width: $medium) {
    padding: 14px;
    font-size: 18px;
  }
  @media screen and (min-width: $medium) {
    padding: 14px 24px;
    font-size: 20px;
  }
}

/* Change the color of links on hover */
.topnav a:hover {
  border-bottom: 2px solid #016e7f;
  color: #016e7f;
}

/* Add a color to the active/current link */
.topnav a.active {
  border-bottom: 3px solid #016e7f;
  color: #016e7f;
  font-weight: 500;
}

// general Styles
.readArticle {
  span {
    color: #333333;
    font-family: 'General Sans';
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    margin-right: 6px;

    cursor: pointer;
    &:hover {
      color: #016e7f;
    }
  }
}

.author-section {
  color: #adadad;
  display: flex;
  gap: 6px;

  .name {
    color: #000;
  }

  .icon {
    width: 20px;
    max-height: 20px;
  }
}

//  List-Grid

.list-grid-wrapper {
  .list-grid-view {
    display: flex;
    justify-content: flex-end;
    gap: 32px;
  }

  .list-wrapper {
    margin: 2.625rem 0;
    .blog-card-list {
      display: flex;
      border-bottom: 1px solid rgba(217, 217, 217, 0.5);
      gap: 32px;
      padding: 60px 0px;

      @media screen and (min-width: 0) and (max-width: $medium) {
        flex-direction: column;
      }
      @media screen and (min-width: $medium) {
        flex-direction: row;
      }

      // &:last-child {
      //   border: none;
      // }

      .image-list-cont {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        overflow: hidden;
        max-width: 422px;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 3px 8.6px 0px rgba(51, 51, 51, 0.25);

        .image-tag {
          position: absolute;
          top: 15px;
          left: 15px;
          border-radius: 32px;
          padding: 2px 12px 2px 12px;
          color: #016e7f;
          background-color: #fff;
          font-size: 13px;
        }
      }
    }
    .blog-info-list {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        padding: 0 0 10px 0;
        margin: 0;
      }
      .desc {
        color: #333333;

        font-weight: '500';
        line-height: 24px;

        @media screen and (min-width: 0) and (max-width: $medium) {
          display: none;
          font-size: 16px;
        }
        @media screen and (min-width: $medium) {
          display: block;
          font-size: 20px;
        }
      }
    }
  }

  .grid-wrapper {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(458px, 1fr));
    gap: 32px;
    .blog-card-list {
      gap: 20px;
      margin: 2rem 0;
      // padding-bottom: 30px;
      // border-bottom: 1px solid #bbbbbb;

      // &:last-child {
      //   border: none;
      // }

      .image-list-cont {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 10px;

        .image-tag {
          position: absolute;
          top: 15px;
          left: 15px;
          border-radius: 32px;
          padding: 2px 12px 2px 12px;
          color: #016e7f;
          background-color: #fff;
          font-size: 13px;
        }
      }
    }
    .blog-info-list {
      display: flex;
      flex-direction: column;
      gap: 9px;
      justify-content: center;

      .blog-title {
        order: 2;
      }
      .blog-date {
        order: 1;
      }
      .desc {
        display: none;
      }

      .readArticle {
        order: 4;
      }
      p {
        padding: 0;
        margin: 0;
      }
      .desc {
        font-size: 20px;
        font-weight: '600';
      }
    }
  }
}

// Articles

.articleContainer {
  margin: 5rem auto;
  max-width: 1080px;
  border: 1px solid #dddddd;
  padding: 5rem;

  .article-title {
    font-size: 36px;
  }
  .image-container {
    max-width: 100%;
    background-size: cover;
    background-position: center;

    .image-desc {
      color: #000;
    }
    .image-title {
      font-size: 36px;
    }
  }

  #mainSection {
    font-size: 20px;

    p {
      margin-top: 0px;
      line-height: 2rem;
    }
    .sub-title {
      font-size: 24px;
    }
    .art-title {
      font-size: 30px;
      font-weight: 600;
    }
    .list {
      margin-left: 40px;
      p {
        display: list-item;
      }
    }
    li {
      line-height: 2.5rem;
    }
    .quoteContainer {
      border-left: 2px solid black;
      padding: 5px 3rem 1rem 2rem;
      word-spacing: 5px;
      line-height: 2rem;

      max-width: 90%;
      min-width: 85%;
    }
  }
}
.bottomContainer {
  margin: 2rem auto;
  max-width: 1080px;
}

@media screen and (max-width: 496px) {
  .articleContainer {
    #mainSection {
      .quoteContainer {
        border-left: 2px solid black;
        padding-right: 0;
      }
    }
  }
}
