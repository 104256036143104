$small: 475px;
$medium: 990px;

.footer {
  background-color: #016e7f;
  padding: 40px 104px;
  @media screen and (max-width: $medium) {
    padding: 20px 14px;
  }

  .upper-foot {
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    order: 2;

    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .logo-section {
      order: 1;
      .logo-gally {
        max-width: 332px;
        padding-bottom: 14px;
        @media screen and (max-width: $small) {
          img {
            width: 280px;
          }
          a {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }
      }
      .icons-div {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 0;
        margin: 0;

        @media screen and (max-width: $medium) {
          display: flex;
          flex-direction: column;
        }

        .icons {
          display: flex;
          gap: 14px;
          border-right: 1px solid white;
          margin: 10px 0;
          padding: 0 14px;

          @media screen and (max-width: $medium) {
            border: none;
            gap: 34px;
          }

          img {
            width: 32px;
          }
        }
        .foot-note {
          p {
            padding: 0;
            margin: 0 auto;
          }
        }
      }
    }

    .shortcuts{
      gap: 5rem;
    }

    h3 {
      color: white;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 14px;
      margin: 0;
    }
    a {
      font-size: 14px;
      cursor: pointer;
      color: white;
      text-decoration: none;
    }
    p {
      padding: 4px 0;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #BFFAFF;
      }
    }
  }

  .lower-foot {
    text-align: end;
    padding: 24px 0;

    @media screen and (max-width: $small) {
      text-align: center;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: white;
    }
  }
}
